/* Bootstrap 5 improvement by Jorr.it */

.form-floating
{
  > .form-control, 
  > .form-select,
  > .vdp-datepicker .form-control {
    border-radius: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-color: rgb(33, 37, 41);
    padding: 23px 0 0 4px !important;
  }

  > .vdp-datepicker .form-control {
    padding: 26px 0 3px 4px !important;
  }  

  > label {
    top: 10px;
    opacity: .65;
    padding-left: 4px;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .vdp-datepicker,
  > .form-select {
    ~ label {
      opacity: .65;
      transform: scale(.85) translateY(-20px); 
    }
  }
  
  > .form-control:disabled,
  > .vdp-datepicker .form-control:disabled,
  > .form-control:read-only {
    background-color: transparent;
    border-style: dashed;
  }

  > .vdp-datepicker .form-control:read-only {
    background-color: transparent;
  }

  > textarea {
    height: 100px !important;
  } 
  
  .form-select {
    background-position-y: 30px;
    padding-right: 25px !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
  
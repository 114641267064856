@import 'variables.scss';

* {
    /* This removes light blue outline around buttons etc */
    &:focus, &.focus,
    &:active, &.active {
        outline: transparent none 0 !important;
        box-shadow: 0 0 0 0 rgba(0,123,255,0) !important;
        -webkit-box-shadow: none !important;
    }
}  

html {
    font-size: 14px;
}

.container {
    max-width: 1140px; // no xxl
}

.fixed-header {
	position: fixed;
  	top: 0; 
    left: 50%;
    transform: translate(-50%, 0);	
    width: 100%;
    z-index: 1030; // Under #nprogress
    background-color: #fff;
    .navbar-brand {
        width: 150px;
        height: 25px;
    }
    #logo {
        transition: 0.1s;
        position: absolute;
    }
}

.container-content {
	min-height: 700px;
	margin-top: 135px;
}

.text-truncate {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

.navbar, .nav-pills {
    .nav-link.active {
        color: $gemon-blue !important;
    }

    .nav-link {
        color: #19bdc3 !important;
    }

    .nav-link.active {
        font-weight: bold;
        background-color: transparent;
    }
}

.navbar-layout .nav-item {
    margin-left: 20px
}

.btn-primary {
    background-color: $gemon-blue !important;
}

.btn-primary:hover {
    background-color: #0c5894 !important;
  }

.navbar-gradient {
    background: linear-gradient(90deg, #fff 50%, $gemon-blue 50%);

    .container {
        height: 4px;
        background: linear-gradient(90deg, #fff 15%, $gemon-blue 100%);
    }    
}

i.bi {
    font-style: normal;
}

.btn-toggle::before {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    display: inline-block;
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.bg-orange {
    color: #fff;
    background-color: $gemon-orange;
}

.scrollable {
    overflow-y: auto; 
    max-height: 500px;
}

.btn-wide {
    width: 100px
}

.btn-full {
    width: 100%;
}

input.form-error, select.form-error {
    border-bottom-color: #dc3545 !important;
}

label.form-error {
    color: #dc3545;
}

textarea.form-error {
    outline: none !important;
    border: 1px solid #dc3545;
}

.field-error {
    color: #dc3545;
    font-size: 11px;
    padding-left: 4px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $gemon-blue;
}

.page-title {
    margin-top: -56px;
    margin-bottom: 20px;
    text-align: right;

    h6 {
        color: #aaa;  
    }
}



a {
    color: #212529;
}

// google chart bug fix
svg > g > g:last-child { pointer-events: none }
